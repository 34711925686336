import { FC, Fragment, useCallback, useEffect, useRef } from 'react'
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import { isNil, isEmpty } from 'ramda'
import Link from 'next/link'
import { twMerge } from 'tailwind-merge'

import BellIcon from '~/src/components/generic/PhosphorIcons/BellIcon'
import Button from '~/src/components/generic/Button'
import Tooltip from '~/src/components/generic/Tooltip'

import { useNotification } from '~/src/components/shared/Notification/hooks/useNotification'
import NotificationContainer from '~/src/components/shared/Notification/components/NotificationContainer'
import useClickNotification from '~/src/components/shared/Notification/hooks/useClickNotification'
import { Notification } from '~/src/components/shared/Notification/types'
import useMarkNotificationsAsRead from '~/src/components/shared/Notification/hooks/useMarkNotificationsAsRead'
import { UNREAD_NOTIFICATION_COUNT_THRESHOLD } from '~/src/components/shared/features/constants'
import { getNotificationTimeLapsed } from '~/src/components/shared/Notification/helpers'

import useOnClickOutside from '~/hooks/useOnClickOutside'

import colors from '~/config/colorsScheme'
import { MixpanelEvent, track } from '~/clients/mixpanelClient'

type Props = {
  onOpenSettings: () => void
}

const NotificationMenu: FC<Props> = ({ onOpenSettings }) => {
  const notificationContext = useNotification()
  const [createHref] = useClickNotification()
  const [markNotificationsAsRead] = useMarkNotificationsAsRead()
  const menuRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (notificationContext?.isDropdownOpen) {
      track(MixpanelEvent.NotificationCenterOpened, {
        numberOfNotifications: notificationContext?.notifications.length,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationContext?.isDropdownOpen])

  const trackNotificationSelected = (notification: Notification) => {
    track(MixpanelEvent.NotificationSelected, {
      source: 'Notification Center',
      notificationType: notification.type,
      notificationTier: notification.tier,
      age: getNotificationTimeLapsed(notification.timestamp, true),
      load: notification.load?.reference,
      driver: notification.driver?.fullname,
    })
  }

  const onNotificationClickHandler = (clickedNotification: Notification) => {
    trackNotificationSelected(clickedNotification)
    isNil(clickedNotification.readAt) && markNotificationsAsRead([clickedNotification])
    notificationContext?.setIsDropdownOpen(false)
  }

  const unreadNotificationCount =
    notificationContext?.notifications.filter((notification) => !notification.readAt)
      .length ?? 0

  const handleDropdownOpen = useCallback(() => {
    notificationContext?.setIsDropdownOpen(!notificationContext.isDropdownOpen)
  }, [notificationContext])

  useOnClickOutside(menuRef, () => {
    notificationContext?.setIsDropdownOpen(false)
  })

  return (
    <Menu ref={menuRef} as="div" className="relative inline-block text-left">
      <Tooltip
        content="Notifications"
        placement="bottom"
        arrowPlacement="right"
        className="-right-[40%] left-[unset] translate-x-0 p-2"
      >
        <MenuButton
          className="p-2"
          aria-label="Open-notifications"
          onClick={handleDropdownOpen}
        >
          <div className="relative flex">
            <BellIcon size="28" color={colors.gray[600]} />
            {unreadNotificationCount > 0 && (
              <div className="absolute left-4 z-40 flex h-3 w-3.5 items-center justify-center rounded-lg bg-red-600">
                <div className="h-2 text-4xs text-white">
                  {unreadNotificationCount > UNREAD_NOTIFICATION_COUNT_THRESHOLD
                    ? UNREAD_NOTIFICATION_COUNT_THRESHOLD
                    : unreadNotificationCount}
                </div>
              </div>
            )}
          </div>
        </MenuButton>
      </Tooltip>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems className="absolute right-0 top-10 z-[999] mt-2 w-96 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
          <div
            className={twMerge(
              'flex max-h-96 flex-col overflow-auto',
              notificationContext?.notifications?.length ? 'pt-6' : 'py-6'
            )}
            data-testid="notification-menu"
          >
            <div className="text-md items-center px-6 pb-6 font-medium">
              Notifications
            </div>
            {notificationContext?.notifications?.length ? (
              <>
                {notificationContext?.notifications?.map((notification) => (
                  <Link key={notification.id} href={createHref(notification)}>
                    <MenuItem
                      className="py-3 hover:bg-gray-25"
                      as="div"
                      onClick={() => onNotificationClickHandler(notification)}
                    >
                      <NotificationContainer notification={notification} />
                    </MenuItem>
                  </Link>
                ))}
              </>
            ) : (
              <div className="flex justify-between gap-2 px-6 py-3 text-xs">
                <div className="font-medium leading-normal text-gray-400">
                  No new notifications for your favorite cost centers in the last 24
                  hours
                </div>
                <MenuItem>
                  <Button
                    variant="link"
                    onClick={onOpenSettings}
                    className="w-36 font-extrabold text-gray-900 hover:bg-gray-50"
                  >
                    View favorites
                  </Button>
                </MenuItem>
              </div>
            )}
          </div>
          {!isEmpty(notificationContext?.notifications) && (
            <div className="border-t py-3 text-center">
              <Button
                variant="link"
                className="text-xs font-semibold text-gray-900 hover:bg-gray-50"
                onClick={() => {
                  markNotificationsAsRead(
                    notificationContext?.notifications as Array<Notification>
                  )
                }}
              >
                Mark all as read
              </Button>
            </div>
          )}
        </MenuItems>
      </Transition>
    </Menu>
  )
}

export default NotificationMenu
