import { isNil } from 'ramda'

import {
  View,
  notificationTierRank,
  Notification,
} from '~/src/components/shared/Notification/types'
import { MILLISECONDS_TO_HOURS } from '~/src/components/shared/features/constants'
import { NotificationDisplayViolation } from '~/src/components/shared/Notification/constants'

import { RiskTier, NotificationType } from '~/__generated_types__/globalTypes'

const isNotificationForStop = (
  notification: Notification,
  currentStop: { loadId?: number; legIdx?: number; stopIdx?: number }
) => {
  return (
    notification.loadId === currentStop.loadId &&
    notification.legIdx === currentStop.legIdx &&
    (notification.stopIdx === currentStop.stopIdx ||
      (isNil(notification.stopIdx) && currentStop.stopIdx === 1))
  )
}

/**
 * @returns Returns a boolean indicating whether the icon should be displayed or not in the detail or map view.
 * @description - The icon should be displayed when:
 *  - notification type is LegNotStarted and stopIdx is 1. *TO BE REMOVED*
 *  - notification type is LoadNotStarted and stopIdx is 1.
 *  - notification type is Detention and legIdx and stopIdx is equal to the respective indexes of the notification.
 */
const getNotificationForStop = (
  notifications: Array<Notification>,
  currentStop: { loadId?: number; legIdx?: number; stopIdx?: number },
  expectedType?: NotificationType
) => {
  if (!expectedType) return

  // TODO: This will be cleaned up on removal of LegNotStarted notifications
  // https://app.asana.com/0/1209185025662795/1209129580432423/f
  const isNotStarted = (type: NotificationType) =>
    type === NotificationType.LegNotStarted || type === NotificationType.LoadNotStarted
  const typeMatch = isNotStarted(expectedType)
    ? (notification: Notification) => isNotStarted(notification.type)
    : (notification: Notification) => notification.type === expectedType

  const notificationsForStop = notifications?.filter(
    (notification) =>
      isNotificationForStop(notification, currentStop) &&
      typeMatch(notification) &&
      notification.tier !== RiskTier.Resolved
  )

  return getHighestPriorityNotification(notificationsForStop)
}

/**
 * @returns Returns the highest priority notification for a load or stop based on the RiskTier ranking.
 * Undefined will be returned if no notifications are passed in.
 * @description notificationTierRank is an enum used to rank level of priority of notifications for a load.
 * - Critical has the highest priority
 * - Resolved has the lowest priority.
 * - Risk is in between Critical and Resolved.
 */
const getHighestPriorityNotification = (
  notifications?: Array<Notification>
): Notification | undefined => {
  if (!notifications?.length) return

  return notifications.reduce((highest, notification) => {
    return notificationTierRank[notification.tier] > notificationTierRank[highest.tier]
      ? notification
      : highest
  })
}

/**
 * @returns Returns a boolean indicating whether the icon should be displayed or not.
 * @description - The icon should be displayed when:
 *  - notification type is Detention, view is detailView or mapView, and legIdx and stopIdx is equal to the respective indexes of the notification.
 *  - notification type is LegNotStarted, view is listView, and loadId is equal to the loadId of the load. *TO BE REMOVED*
 *  - notification type is LegNotStarted, view is detailView or mapView, and stopIdx is 1. *TO BE REMOVED*
 *  - notification type is LoadNotStarted, view is detailView or mapView, and stopIdx is 1.
 *  - notification type is LoadNotStarted, view is listView, and loadId is equal to the loadId of the load.
 */
const notificationForIconDisplay = (
  view: View,
  currentLoad: { loadId?: number; legIdx?: number; stopIdx?: number },
  notifications?: Array<Notification>
) => {
  if (view === View.ListView || view === View.DVDView) {
    return getHighestPriorityNotification(
      notifications?.filter(
        (notification) => notification.loadId === currentLoad.loadId
      )
    )
  } else {
    return getHighestPriorityNotification(
      notifications?.filter((notification) =>
        isNotificationForStop(notification, currentLoad)
      )
    )
  }
}

const getNotificationHoverText = (
  numberNotifications?: number,
  priorityNotificationTier?: RiskTier,
  priorityNotificationType?: NotificationType
): string | undefined => {
  if (!numberNotifications || !priorityNotificationTier || !priorityNotificationTier)
    return

  const notificationTypeHoverText = priorityNotificationType
    ? `${
        NotificationDisplayViolation[priorityNotificationType]
      } (${priorityNotificationTier.toLowerCase()})`
    : undefined
  const additionalNotifications =
    numberNotifications > 1 ? `, +${numberNotifications - 1}` : ''

  return notificationTypeHoverText
    ? notificationTypeHoverText + additionalNotifications
    : undefined
}

const getNotificationTimeLapsed = (
  timestamp: string,
  isHourAndMinuteFormat?: boolean
): string => {
  const notificationTime = new Date(timestamp).getTime()
  const currentTime = new Date().getTime()
  const differenceInMilliseconds = currentTime - notificationTime

  const hours = Math.floor(differenceInMilliseconds / MILLISECONDS_TO_HOURS)
  const minutes = Math.floor(
    (differenceInMilliseconds % MILLISECONDS_TO_HOURS) / (1000 * 60)
  )

  if (isHourAndMinuteFormat) {
    return `${hours.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}:${minutes.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    })}`
  }

  return hours ? `${hours}h` : `${minutes}m`
}

export {
  getNotificationForStop,
  getHighestPriorityNotification,
  notificationForIconDisplay,
  getNotificationHoverText,
  getNotificationTimeLapsed,
}
